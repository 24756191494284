<template>
  <b-card footer-class="text-muted">
    <b-row class="mb-3">
      <b-col lg="12">
        <h1>Escriba los datos del paciente</h1>
      </b-col>
      <b-col lg="12">
        <b-form @submit.prevent="createUser(user)">
          <b-row>
            <b-col cols="6" lg="4">
              <b-form-group label="Nombre" label-for="Nombre">
                <b-form-input v-model="user.name" placeholder="Nombre"  />
              </b-form-group>
            </b-col>
            <b-col cols="6" lg="4">
              <b-form-group label="Numero de expediente" label-for="expe">
                <b-form-input
                  readonly="readonly"

                  v-model="user.patient_file_number"
                  placeholder="Numero de expediente"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6" lg="4">
              <b-form-group>
                <h5>Fecha de nacimiento</h5>
                <flat-pickr
                  v-model="user.birthday"
                  class="form-control"
                  :config="{
                    enableTime: false,
                    dateFormat: 'Y-m-d',
                    locale: {
                      firstDayOfWeek: 1,
                      weekdays: {
                        shorthand: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
                        longhand: [
                          'Domingo',
                          'Lunes',
                          'Martes',
                          'Miércoles',
                          'Jueves',
                          'Viernes',
                          'Sábado',
                        ],
                      },
                      months: {
                        shorthand: [
                          'Ene',
                          'Feb',
                          'Mar',
                          'Abr',
                          'May',
                          'Jun',
                          'Jul',
                          'Ago',
                          'Sep',
                          'Оct',
                          'Nov',
                          'Dic',
                        ],
                        longhand: [
                          'Enero',
                          'Febreo',
                          'Мarzo',
                          'Abril',
                          'Mayo',
                          'Junio',
                          'Julio',
                          'Agosto',
                          'Septiembre',
                          'Octubre',
                          'Noviembre',
                          'Diciembre',
                        ],
                      },
                    },
                  }"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6" lg="3">
              <b-form-group label="Correo" label-for="h-email">
                <b-form-input
                  v-model="user.email"
                  id="h-email"
                  type="email"
                  placeholder="Correo"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6" lg="3">
              <b-form-group label="Teléfono" label-for="h-number">
                <b-form-input
                  v-model="user.phone"
                  id="h-number"
                  type="number"
                  @keypress="isNumber($event)"
                  pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                  placeholder="Teléfono"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6" lg="3">
              <b-form-group label="Celular" label-for="h-number">
                <b-form-input
                  v-model="user.mobile"
                  id="h-number1"
                  @keypress="isNumber($event)"
                  type="number"
                  placeholder="Celular"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="3">
              <b-form-group
                  label="Tipo de Paciente"
                  label-for="h-email"
              >
                <v-select
                    @input="pricelistVerify()"
                    v-model="select_price_list"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="value"
                    :options="pricelists"
                />
              </b-form-group>

            </b-col>
            <b-col cols="12" v-if="!getUser.appointee_id">
              <b-row>
                <b-col cols="6" lg="4">
                  <b-form-group label="País" label-for="h-email">
                    <vue-autosuggest
                      :suggestions="countries"
                      :input-props="{
                        id: '',
                        class: 'form-control',
                        placeholder: 'Seleccionar país',
                      }"
                      @input="getCountrySelect"
                      @selected="countryVerify"
                    >
                      <template slot-scope="{ suggestion }">
                        <span class="my-suggestion-item">{{ suggestion.item.name }}</span>
                      </template>
                    </vue-autosuggest>
                  </b-form-group>
                </b-col>
                <b-col cols="6" lg="4">
                  <b-form-group label="Estado" label-for="h-email">
                    <v-select
                      @input="selectState()"
                      v-model="select_state"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="value"
                      :options="states"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6" lg="4">
                  <b-form-group label="Municipios" label-for="h-email">
                    <v-select
                      v-model="select_cities"
                      @input="selectCitie()"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="value"
                      :options="cities"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6" lg="4">
                  <b-form-group label="Cita con" label-for="h-email">
                    <v-select
                      @input="groupsVerify()"
                      v-model="select_group"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="value"
                      :options="groups"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6" lg="4">
                  <b-form-group label="Doctores" label-for="h-email">
                    <v-select
                      @input="changeMedical()"
                      v-model="select_medical"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="value"
                      :options="medicals"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="6" lg="4">
                  <b-form-group label="Colonia" label-for="h-number">
                    <b-form-input
                      v-model="user.l10n_mx_edi_colony"
                      id="colony"
                      placeholder="Colonia"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6" lg="4">
                  <b-form-group label="Codigo postal" label-for="h-number">
                    <b-form-input
                        @keypress="isNumber($event)"
                      v-model="user.zip"
                      id="zip"
                      placeholder="Codigo postal"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="6" lg="4">
                  <b-form-group label="Calle" label-for="h-number">
                    <b-form-input
                      v-model="user.street_name"
                      id="name"
                      placeholder="Calle"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6" lg="4">
                  <b-form-group label="Numero Casa" label-for="h-number">
                    <b-form-input
                      v-model="user.street_number"
                      id="num"
                      placeholder="Numero Casa"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6" lg="4">
                  <b-form-group label="Rfc" label-for="h-number">
                    <b-form-input v-model="user.vat" id="rfc" placeholder="rfc" />
                  </b-form-group>
                </b-col>
                <b-col cols="6" lg="4">
                  <b-form-group label="Regimen Fiscal" label-for="h-email">
                    <v-select
                        @input="changeRegimen()"
                        v-model="regimen_select"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="value"
                        :options="regimens"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <!-- submit and reset -->
            <b-col cols="12">
              <b-button
                style="width: 100%"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
              >
                Guardar
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardText,
  BLink,
  BRow,
  BCol,
  BInputGroup,
  BFormInput,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormGroup,
  BFormTextarea,
  BButton,
  BAvatar,
  BForm,
} from "bootstrap-vue";
import vSelect from "vue-select";
import StatisticCardWithAreaChart from "@core/components/statistics-cards/StatisticCardWithAreaChart";
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical";
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";
import { mapGetters, mapActions } from "vuex";
import { VueAutosuggest } from "vue-autosuggest";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: "AddPatient",
  components: {
    BForm,
    BCard,
    BCardText,
    BLink,
    BRow,
    BCol,
    StatisticCardWithAreaChart,
    StatisticCardVertical,
    BFormTextarea,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BButton,
    BAvatar,
    flatPickr,
    vSelect,
    VueAutosuggest,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      select_state: { id: "", value: "" },
      select_cities: { id: "", value: "" },
      regimen_select: { id: "", value: "Seleccione un regimen" },
      select_medical: { id: "", value: "Seleccione un medico" },
      countries: [],
      states: [],
      cities: [],
      medicals: [],
      get_country: "",
      select_group: { id: "", value: "" },
      groups: [],
      pricelists: [],
      regimens:[
        {id: "601", value:"General de Ley Personas Morales"},
        {id: "603", value:"Personas Morales con Fines no Lucrativos"},
        {id: "605", value:"Sueldos y Salarios e Ingresos Asimilados a Salarios"},
        {id: "606", value:"Arrendamiento"},
        {id: "608", value:"Demás ingresos"},
        {id: "609", value:"Consolidación"},
        {id: "610", value:"Residentes en el Extranjero sin Establecimiento Permanente en México"},
        {id: "611", value:"Ingresos por Dividendos (socios y accionistas)"},
        {id: "612", value:"Personas Físicas con Actividades Empresariales y Profesionales"},
        {id: "614", value:"Ingresos por intereses"},
        {id: "616", value:"Sin obligaciones fiscales"},
        {id: "620", value:"Sociedades Cooperativas de Producción que optan por diferir sus ingresos"},
        {id: "621", value:"Incorporación Fiscal"},
        {id: "622", value:"Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras"},
        {id: "623", value:"Opcional para Grupos de Sociedades"},
        {id: "624", value:"Coordinados"},
        {id: "628", value:"Hidrocarburos"},
        {id: "607", value:"Régimen de Enajenación o Adquisición de Bienes"},
        {id: "629", value:"De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales"},
        {id: "630", value:"Enajenación de acciones en bolsa de valores"},
        {id: "615", value:"Régimen de los ingresos por obtención de premios"},
        {id: "625", value:"Régimen de las actividades empresariales con ingresos a traves de plataformas inalambricas"},
        {id: "626", value:"Régimen Simplificado de confianza"},
      ],
      select_price_list: {id: 27, value: 'Publico General'},
      user: {
        name: "",
        patient_file_number: "",
        l10n_mx_edi_fiscal_regime: "",
        property_product_pricelist: 27,
        birthday: null,
        phone: "",
        mobile: "",
        email: "",
        appointee_id: "",
        is_patient: true,
        street_name: null,
        street_number: null,
        city_id: null,
        state_id: null,
        country_id: null,
        zip: null,
        l10n_mx_edi_colony: null,
        l10n_mx_edi_locality: null,
        vat: "XEXX010101000",
      },
    };
  },
  async created() {
    if (!this.getUser.appointee_id) {
      await this.getGroupSelect();
      await this.getPriceListSelect();
    } else {
    }
    this.user.patient_file_number = await this.findConsecutiveId();
  },
  methods: {
    ...mapActions("patient", ["createPatient", "findConsecutiveId"]),
    ...mapActions("appointment", [
      "findAppointmentDoctor",
      "findAppointmentGroups",
      "findApointeeSearch",
       "findAppointmentPriceList"
    ]),
    ...mapActions("address", ["findCountry", "findCountryState", "findCountryCities"]),
    async groupsVerify() {
      await this.searchGroup(this.select_group.id);
    },
    async getPriceListSelect() {

      this.pricelists = []
      const response = await this.findAppointmentPriceList()
      for (const a of response) {
        this.pricelists.push({
          id: a.id,
          value: a.name,
        })
      }
    },
    changeMedical() {
      this.user.appointee_id = this.select_medical.id;
    },
    changeRegimen() {
      this.user.l10n_mx_edi_fiscal_regime = this.regimen_select.id;
    },
    selectCitie() {
      this.user.city_id = this.select_cities.id;
    },
    async pricelistVerify() {
      this.user.property_product_pricelist = this.select_price_list.id;
    },
    async getGroupSelect() {

      this.groups = [];
      const response = await this.findAppointmentGroups();
      for (const a of response) {
        if (!a.is_place) {
          this.groups.push({
            id: a.id,
            value: a.group_name,
            verifed: a.is_place,
          });
        }
      }
    },
    async searchGroup(id) {
      const response = await this.findApointeeSearch(id);
      this.medicals = [];
      for (const a of response) {
        this.medicals.push({
          id: a.ResPartnerAppointment.id,
          value: a.ResPartnerAppointment.name,
        });
      }
    },
    async findDoctor() {
      const response = await this.findAppointmentDoctor();
      this.medicals = [];
      for (const a of response) {
        this.medicals.push({
          id: a.id,
          value: a.name,
        });
      }
    },

    async verifiedForm(type) {
      if (type) {
        if (this.user.name === "") {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Campo nombre requerido",
              icon: "error",
              variant: "warning",
            },
          });
          return false;
        }
        if (!this.user.mobile) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Campo Celular requerido",
              icon: "error",
              variant: "warning",
            },
          });
          return false;
        }
        if (this.user.mobile.length !== 10 ) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Campo Celular incorrecto",
              icon: "error",
              variant: "warning",
            },
          });
          return false;
        }
        if (this.user.phone !== "") {
          if (this.user.phone.length !== 10 )
          {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Campo Telefono necesita 10 caracteres",
                icon: "error",
                variant: "warning",
              },
            });
            return false;
          }
        }
        return true;
      } else {
        if (this.user.name === "") {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Campo nombre requerido",
              icon: "error",
              variant: "warning",
            },
          });
          return false;
        }
        if (this.user.email !== "") {
          var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

          if (reg.test(this.user.email) === false)
          {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Campo email no es un correo",
                icon: "error",
                variant: "warning",
              },
            });
            return false;
          }
        }
        if (this.user.phone !== "") {
          if (this.user.phone.length !== 10 )
          {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Campo Telefono necesita 10 caracteres",
                icon: "error",
                variant: "warning",
              },
            });
            return false;
          }
        }
        if (!this.user.mobile) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Campo Celular requerido",
              icon: "error",
              variant: "warning",
            },
          });
          return false;
        }
        if (this.user.mobile.length !== 10 ) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Campo Celular necesita 10 caracteres",
              icon: "error",
              variant: "warning",
            },
          });
          return false;
        }
        if (!this.user.appointee_id) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Campo doctor requerido",
              icon: "error",
              variant: "warning",
            },
          });
          return false;
        }
        return true;
      }
    },
    async createUser(user) {
      let response;
      if (this.getUser.appointee_id) {
        const ver = await this.verifiedForm(true);
        if (!ver) {
          return;
        }
        this.user.appointee_id = this.getUser.appointee_id;
        const send = {
          patient_file_number: this.user.patient_file_number,
          birthday: this.user.birthday,
          phone: this.user.phone,
          mobile: this.user.mobile,
          email: this.user.email,
          name: this.user.name,
          appointee_id: this.user.appointee_id,
        };

        response = await this.createPatient(send);
      } else {
        const ver = await this.verifiedForm(false);
        if (!ver) {
          return;
        }
        if (!this.user.birthday){
          this.user.birthday = null
        }
        response = await this.createPatient(this.user);
      }

      if (response.msg) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Usuario Creado",
            icon: "EditIcon",
            variant: "success",
          },
        });
        await this.$router.push("/pacientes");
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: response.error,
            icon: "error",
            variant: "warning",
          },
        });
      }
    },
    async countryVerify(item) {
      this.user.country_id = item.item.id;
      this.states = [];
      const response = await this.findCountryState(this.user.country_id);
      for (const a of response) {
        this.states.push({
          id: a.id,
          value: a.name,
        });
      }
      await this.searchCities();
    },
    async searchCities() {
      this.cities = [];
      let data = {};
      if (this.user.state_id) {
        data = {
          country_id: this.user.country_id,
          state_id: this.user.state_id,
        };
      } else {
        data = {
          country_id: this.user.country_id,
          state_id: null,
        };
      }
      const response = await this.findCountryCities(data);
      for (const a of response) {
        this.cities.push({
          id: a.id,
          value: a.name,
        });
      }
    },
    async selectState() {
      this.user.state_id = this.select_state.id;
      await this.searchCities();
    },
    async getCountrySelect(text) {
      if (text === "" || text === undefined) {
        this.countries = [];
        return;
      }
      this.countries = [];
      let cou = [];
      const response = await this.findCountry(text);
      for (const a of response) {
        cou.push({
          id: a.id,
          name: a.name,
        });
      }
      this.countries = [
        {
          data: cou,
        },
      ];
    },
  },
  computed: {
    ...mapGetters("auth", ["getUser"]),
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-autosuggest.scss";
</style>
